import React from 'react';
import './body.css';
import { Container, Row, Col } from 'react-bootstrap';

const AboutGym = () => {
    return (
        <div id='About' className='mainbanner3'>
            <Container className='bannerthree'>
                <Row className='banner3'>
                    <Col md='6'>
                        <div className='main_title'>
                            <h1 className='tit1'>ASPIROM GYM </h1>
                            <h1 className='tit2'>Management Software </h1>
                            <p>Gym management systems provide fitness businesses the functionality to manage attendance, schedules, memberships, Invoice, Payments and facilities. The capabilities of gym management systems include storing member information in a database, managing financial records, scheduling classes, and reserving facilities. Gym and club management systems can be utilized by organizations in a variety of fitness-focused organizations and businesses. These include athletic clubs and park districts, as well as dance, yoga, aerobics, swimming, and fitness centers. Gym management systems may integrate with other types of software to perform specific tasks at a higher level. These integrations may include inventory software to manage stocks, Payment gateways, POS, CRM, email tools to increase interaction between members and managers, or social media marketing  tools to increase brand awareness.
                            </p>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='banner-txt3'>
                            <img src='./banner_2.png' alt='none'></img>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AboutGym