
import React from 'react';
import './body.css';
import { Container, Row, Col } from 'react-bootstrap';

const Overview = () => {
  return (
    <div id='Overview' className='overviewBanner'>
            <Container className='overview-ctr'>
                <Row className='overview-row'>
                    <Col className='overview-main-title' md='12'>
                    <h1>AN OVERVIEW OF<br></br> ASPIROM GYM</h1>
                    </Col>
                 </Row>
                <Row className='overview-row-box'>
                <Col md='4'>
                        <div className='overview-card-1'>
                        <div className='overview-icon'>
                            <img src='/packageixon.png' alt='pkgicon'></img>
                        </div>
                        <div className='overview-title'>
                            <h2>Package Management</h2>
                        </div>
                        </div>
                    </Col>
                    <Col md='4'>
                        <div className='overview-card-1'>
                        <div className='overview-icon'>
                            <img src='/packageixon.png' alt='pkgicon'></img>
                        </div>
                        <div className='overview-title'>
                            <h2>Membership Management</h2>
                        </div>
                        </div>
                    </Col>
                    <Col md='4'>
                        <div className='overview-card-1'>
                        <div className='overview-icon'>
                            <img src='/packageixon.png' alt='pkgicon'></img>
                        </div>
                        <div className='overview-title'>
                            <h2>Account Management </h2>
                        </div>
                        </div>
                    </Col>
                 </Row>
            </Container>
        </div>
  )
}

export default Overview