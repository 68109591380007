import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Row, Col } from 'react-bootstrap';
import './body.css';

const Body = () => {
  return (
    <>
      <div id='Home' className='banner-main'>
        <Row className='textdec'>
          <Col md="8" className='text-allignment'>
            <img className='logoastore' src="./logo_main.png" alt="mainlogo" />
            <div className='banner-txt'>
              {/* <h1 className='main-heading'>ASPIROM GYM</h1> */}
              <h1>GYM Management <br></br>Software</h1>
              <Button className='freedemo-btn'>Get Free Demo</Button>
            </div>
          </Col>
        </Row>
      </div>
    </>

  )
}

export default Body