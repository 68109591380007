import React from 'react';
import './header.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Nav, Navbar, Button} from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from "react-icons/fa";

const Header = () => {
    return (
        <div>
            <Navbar className='navbar-c' expand="lg" fixed="bottom ">
                <Container className='navbar-container'>
                    {/* <Navbar.Brand href="#home">
                        <img src="./logo_main.png" alt="mainlogo"/>
                        Aspirom
                        </Navbar.Brand> */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                        <Nav.Link href="#Home">Home</Nav.Link>
                            <Nav.Link href="#introduction">Introduction</Nav.Link>
                            <Nav.Link href="#About">About</Nav.Link>
                            <Nav.Link href="#Overview">Overview</Nav.Link>
                            <Nav.Link href="#Keyfeatures">Key Features</Nav.Link>
                            <Nav.Link href="#GetinTouch">Get In Touch</Nav.Link>
                            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">
                                    Another action
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">
                                    Separated link
                                </NavDropdown.Item>
                            </NavDropdown> */}
                        </Nav>
                    
                    <div className='social'>
                        <div className='facebook'><FaFacebookF/></div>
                        <div className='facebook'><FaInstagram/></div>
                        <div className='facebook'><FaTwitter/></div>
                        <div className='facebook'><FaLinkedinIn/></div>
                    </div>
                    <Button className='freedemo-btn'>Get Free Demo</Button>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Header