import React from 'react';
import './body.css';
import { Container, Row, Col } from 'react-bootstrap';

const Keyfeatures = () => {
    return (

        <div id='Keyfeatures' className='keyfeaturesbanner'>
            <Container className='keyfeatures-ctr'>
                <Row className='keyfeatures-row'>
                    <Col className='keyfeatures-col' md='12'>
                        <h1><span className='mainttle-c'>ASPIROM GYM </span><span className='mainttle-c1'> KEY FEATURES</span></h1>
                    </Col>
                </Row>
                <Row className='keyfeatures-row-card'>
                    <Col className='keyfeatures-col-card' sm="6" md="6" lg="4">
                        <div className='keycard'>
                            <div className='cardtitle'>
                            Privilege based User Login
                            </div>
                            <div className='card-para'>
                            This feature mainly for Front office executive, Instructor, Manager and super admin: As this system handles the clients health and fitness related information privilege or role based access of data prevents data security related issues. Only permitted users can access the information in the system.
                            </div>
                        </div>
                    </Col>
                    <Col className='keyfeatures-col-card' sm="6" md="6" lg="4">
                        <div className='keycard'>
                            <div className='cardtitle'>
                            Customer  Enrollment
                            </div>
                            <div className='card-para'>
                            This feature is used to register a new customer, and the wizard model enrolment screen collects all information of that customer like their health related information, courses they prefer to join, payments etc.
                            </div>
                        </div>
                    </Col>
                    <Col className='keyfeatures-col-card' sm="6" md="6" lg="4">
                        <div className='keycard'>
                            <div className='cardtitle'>
                            Power full Dashboard With 360 Degree Analytic Views

                            </div>
                            <div className='card-para'>
                            Dashboard feature of Aspirom-Gym software furnishes all important information in the dashboard and gives the flexibility to drilldown to the minute  details of the information from the dashboard itself.
                            </div>
                        </div>
                    </Col>
                    <Col className='keyfeatures-col-card' sm="6" md="6" lg="4">
                        <div className='keycard'>
                            <div className='cardtitle'>
                            Member Management

                            </div>
                            <div className='card-para'>
                            This feature is used to monitor their workout - activities, progress ,create workouts specific to that customer, payment management, attendance management etc.
                            </div>
                        </div>
                    </Col>
                    <Col className='keyfeatures-col-card' sm="6" md="6" lg="4">
                        <div className='keycard'>
                            <div className='cardtitle'>
                            Approvals & Notifications
                            </div>
                            <div className='card-para'>
                            Workflow approvals and notifications at key transactional areas gives the management full control over all the activities of the gym , this will enable the gym management a well informed decision making process.
                            </div>
                        </div>
                    </Col>
                    <Col className='keyfeatures-col-card' sm="6" md="6" lg="4">
                        <div className='keycard'>
                            <div className='cardtitle'>
                            Subscription and Membership Renewals
                            </div>
                            <div className='card-para'>
                            This option helps users and customers to renew their existing package and also subscribe for new courses. 
                            </div>
                        </div>
                    </Col>
                    <Col className='keyfeatures-col-card' sm="6" md="6" lg="4">
                        <div className='keycard'>
                            <div className='cardtitle'>
                            Dynamic Course Creation
                            </div>
                            <div className='card-para'>
                            This helps the gym management to define fully configurable courses i.e Personalized and Specialized courses apart from Regular Membership and Addon Packages. That fits to the needs of the customer 
                            </div>
                        </div>
                    </Col>
                    <Col className='keyfeatures-col-card' sm="6" md="6" lg="4">
                        <div className='keycard'>
                            <div className='cardtitle'>
                            Personalized Instructors
                            </div>
                            <div className='card-para'>
                            The instructor module in the software gives instructors the flexibility and freedom to define workouts for the regular customers of the gym, also they can structure courses specific to the needs of their personalized customers. The instructors module helps the instructors to track their customers individually, that offers a required attention to customers of all level.
                            </div>
                        </div>
                    </Col>
                    <Col className='keyfeatures-col-card' sm="6" md="6" lg="4">
                        <div className='keycard'>
                            <div className='cardtitle'>
                            Personalized and Regular Workout Planning Provision 
                            </div>
                            <div className='card-para'>
                            This Feature provides an easy way to control and monitor both personalized and regular workout customers with the right amount of care and attention.
                            </div>
                        </div>
                    </Col>
                    <Col className='keyfeatures-col-card' sm="6" md="6" lg="4">
                        <div className='keycard'>
                            <div className='cardtitle'>
                            Flexible Payment and Invoicing Options 
                            </div>
                            <div className='card-para'>
                            This facility helps the customer and Gym management to make deferred payments (part payments) and track them closely to avoid mistakes.
                            </div>
                        </div>
                    </Col>
                    <Col className='keyfeatures-col-card' sm="6" md="6" lg="4">
                        <div className='keycard'>
                            <div className='cardtitle'>
                            Workout Monitoring &amp; Workout Improvements Recording 
                            </div>
                            <div className='card-para'>
                            This options in the software keep track of the improvements of the customer fitness and helps to make amendments in their routine workouts.
                            </div>
                        </div>
                    </Col>
                    <Col className='keyfeatures-col-card' sm="6" md="6" lg="4">
                        <div className='keycard'>
                            <div className='cardtitle'>
                            Access Control Based Attendance Marking 
                            </div>
                            <div className='card-para'>
                            Gym management can integrate biometric devices to mark the attendance and  control the customer visits .This will automatically mark the number of classes they attended and what is left remaining
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Keyfeatures