import React from 'react';
import './body.css';
import { Container, Row, Col } from 'react-bootstrap';


const Infobanner = () => {
    return (
        <div id='introduction' className='Mainbanner2'>
        <Container className='bannerTwo'>       
            <Row className='banner2'>
                <Col md='10'>
                    <div className='banner-txt2'>
                        <h1 className='main-heading2'>ASPIROM GYM</h1>
                        <h1 className='main-heading3'>Management Software</h1>
                        <div className='banner-txt3'>
                        <img src='./banner_2.png' alt='none'></img>
                    </div>
                        <p>Aspirom-Gym is a Gym management software designed to make it simple and  easy to record and maintain the detailed records of your members and their memberships credentials, book classes and instructors, process and track sales and payments, and communicate with the right members at the right time. Aspirom-Gym is designed to fit Gims/Clubs of all sizes, this gym software is feature-packed. With a full booking system, notifications, website integration, billing integration, a mobile friendly interface  for users,  and 24/7 door access control, Aspirom-Gym  has all you need to more efficiently run your gym.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>
    )
}

export default Infobanner