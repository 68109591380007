import './App.css';
import AboutGym from './components/Body/aboutGym';
import Body from './components/Body/body';
import ContactUs from './components/Body/contactus';
import Infobanner from './components/Body/infoBanner';
import Keyfeatures from './components/Body/keyfeatures';
import Overview from './components/Body/overView';
import Header from './components/header/header';

function App() {
  return (
    <>
    <Header/>
    <Body/>
    <Infobanner/>
    <AboutGym/>
    <Overview/>
    <Keyfeatures/>
    <ContactUs/>
    </>
  );
}

export default App;
